@font-face {
    font-family: "Pretendard";
    font-weight: 100;
    src: url("styles/fonts/Pretendard-ExtraLight.otf") format("truetype");
}
@font-face {
    font-family: "Pretendard";
    font-weight: 200;
    src: url("styles/fonts/Pretendard-Thin.otf") format("truetype");
}
@font-face {
    font-family: "Pretendard";
    font-weight: 300;
    src: url("styles/fonts/Pretendard-Light.otf") format("truetype");
}
@font-face {
    font-family: "Pretendard";
    font-weight: 400;
    src: url("styles/fonts/Pretendard-Regular.otf") format("truetype");
}
@font-face {
    font-family: "Pretendard";
    font-weight: 500;
    src: url("styles/fonts/Pretendard-Medium.otf") format("truetype");
}
@font-face {
    font-family: "Pretendard";
    font-weight: 600;
    src: url("styles/fonts/Pretendard-SemiBold.otf") format("truetype");
}
@font-face {
    font-family: "Pretendard";
    font-weight: 700;
    src: url("styles/fonts/Pretendard-Bold.otf") format("truetype");
}
@font-face {
    font-family: "Pretendard";
    font-weight: 800;
    src: url("styles/fonts/Pretendard-Black.otf") format("truetype");
}
@font-face {
    font-family: "Pretendard";
    font-weight: 900;
    src: url("styles/fonts/Pretendard-ExtraBold.otf") format("truetype");
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
