.image_label {
    display: block;
    position: relative;
    cursor: pointer;

    overflow-x: hidden;
    overflow-y: hidden;
}

.image_label:before {
    position: absolute;
    text-align: center;
}

.image_label {
    background: rgba(0, 0, 0);
    opacity: 0.6;
}
.image_label:before {
    content: "✓";
    color: #fff;
    font-weight: 1000;
    opacity: 1;
    position: absolute;
    font-size: 1.5rem;
    left: 0%;
    right: 0%;
    top: 40.11%;
    bottom: 16.95%;
}
