html {
    font-size: 16px;
}

@media screen and (max-width: 768px) {
    html {
        font-size: 14px;
    }
}

@media screen and (min-width: 1920px) {
    html {
        /* font-size: 20px; */
        /* font-size: 27px; */
        font-size: 18px;
    }
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
